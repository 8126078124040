export default {
  "nav": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "exploreJobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف الوظائف"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل حساب"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل دخول"])},
    "whoUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والأحكام"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التنبيهات"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموافقه على"])}
  },
  "home": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث عن وظيفة"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف الوظائف"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حمل التطبيق الان"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن سيڤي برودكاست"])},
    "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركاء النجاح"])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])},
    "about_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن سيڤي"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روابط تهمك"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم الينا"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتي"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعنا على"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة لموقع سيڤي"])},
    "awamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم وبرمجة مؤسسة أوامر الشبكة"])}
  },
  "job": {
    "cond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الوظيفة"])},
    "qual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤهل"])},
    "similar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وظائف مشابهة"])},
    "infodef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الموهل"])}
  },
  "auth": {
    "specialSpec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص الوظيفي"])},
    "specialPlcSpec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار التخصص الوظيفي"])},
    "phoneRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقل الهاتف مطلوب"])},
    "phoneValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون رقم الهاتف اكثر من ٩ ارقام"])},
    "phoneValid2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون رقم الهاتف بين ٩ او ١٠ ارقام "])},
    "newPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال رقم الجوال الجديد"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال البيانات التاليه لتتمكن من تسجيل الدخول الي حسابك"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "phonePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رقم الجوال"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "passPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال كلمة المرور"])},
    "passPlc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال كلمة المرور الجديدة"])},
    "fotgetPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك مشكلة اثناء التسجيل"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى التواصل معنا"])},
    "newAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك حساب ؟ "])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب جديد"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "userPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم المستخدم"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
    "messagePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اكتب رسالتك هنا"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال"])},
    "fotgetPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رقم الجوال ليتم ارسال كود التحقق الخاص بك"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])},
    "resetPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة تعيين كلمة المرور"])},
    "confirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور"])},
    "confirmPass1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد الكملة المرور الجديدة"])},
    "confirmPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تأكيد كلمة المرور"])},
    "confirmPlc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تأكيد كلمة المرور الجديدة"])},
    "iden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة السر متطابقة"])},
    "notIden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة السر غير متطابقة"])},
    "set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين"])},
    "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود التحقق"])},
    "otpPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال كود التحقق الذي تم ارساله الي رقم جوالك ليتم تفعيل الحساب الخاص بك"])},
    "haveNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تستلم الكود ؟ "])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعد الارسال"])},
    "remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت المتبقي"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثانية"])},
    "regPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال البيانات التاليه لتتمكن من انشاء حساب جديد خاص بك لتستطيع البحث عن الوظائف الجديدة التي تناسبك في اسرع وقت"])},
    "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استكمال البيانات"])},
    "haveAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك حساب ؟ "])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الألكتروني"])},
    "emailPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال البريد الألكتروني"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
    "genderPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد الجنس"])},
    "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد"])},
    "birthPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال تاريخ الميلاد"])},
    "nat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنسية"])},
    "natPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال الجنسية"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "cityPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد المدينة"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
    "countryPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد الدولة"])},
    "noCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد مدينة"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكان الإقامة"])},
    "placePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال مكان الإقامة"])},
    "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيرة الذاتية"])},
    "qual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجة العلمية"])},
    "qualPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد الدرجة العلمية"])},
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص العام"])},
    "fieldPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد التخصص العام"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص الدقيق"])},
    "specialPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد التخصص الدقيق"])},
    "cer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهادات المهنية"])},
    "cerPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد الشهادات المهنية"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهارات"])},
    "certifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهادات المهنية"])},
    "skillsPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد المهارات"])},
    "jobName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسمى الوظيفي"])},
    "ifExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ان وجد"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال المسمى الوظيفي"])},
    "yearsExper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنوات الخبرة"])},
    "yearPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد سنوات الخبرة"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركة الحالية"])},
    "currentPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال الشركة الحالية"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخطي"])},
    "oldPassPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال كلمة المرور القديمة"])},
    "oldPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الحالية"])},
    "pass1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "qualTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤهلات"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الشخصية"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفيديو"])},
    "videoE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع فيديو تعريفي"])},
    "changePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير رقم الجوال"])},
    "changePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاءادخال كلمة المرور الخاصه بك لتتمكن من تغيير رقم الجوال"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا بد من دفع مبلغ 150 ريال للإدارة لاستكمال الحساب الخاص بك"])},
    "payNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادفع الان"])}
  },
  "chat": {
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحادثات"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاثة"])}
  },
  "common": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ التغييرات"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حفظ التغييرات بنجاح"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركة"])},
    "avilable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف المتاحة"])},
    "jobType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الوظيفة"])},
    "showDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التفاصيل"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "contactPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال البيانات التاليه لتتمكن من التواصل معنا والتعرف علي مشكلتك"])},
    "jobField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجال الوظيفي"])},
    "fieldPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار المجال الوظيفي"])},
    "jobTyp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الوظيفية"])},
    "typePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار نوع الوظيفية"])},
    "edQual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤهل التعليمي"])},
    "qualPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار المؤهل التعليمي"])},
    "exper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخبرة"])},
    "experPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار الخبرة"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهارات"])},
    "skillPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار المهارات"])},
    "cer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهادات المهنية"])},
    "cerPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار الشهادات"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
    "avialble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وظيفة متاحة"])},
    "jobDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الوظيفة"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ النشر"])},
    "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الراتب"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات"])},
    "applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التقديم"])},
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم القبول"])},
    "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرفوض"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتهي"])},
    "avilabe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وظيفة متاحة"])},
    "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسل الينا مشكلتك وسنتواصل معك قريبا"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الكل"])},
    "noJobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد وظائف الى الان"])},
    "noSimilar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد وظائف مشابهة"])},
    "applay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقدم للوظيفة"])},
    "res": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهام والمسئوليات"])},
    "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الوظيفة"])},
    "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
    "appliedDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تقديم طلب التقدم للوظيفه برجاء الانتظار"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم رفض طلب تقديمك للوظيفه"])},
    "acceptedDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم قبول طلبك للتقدم للوظيفه"])},
    "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد طلبات الى الان"])},
    "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اقصى مدة للفيديو15 دقيقة "])},
    "match_ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة التطابق"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى تسجيل الدخول لتستطيع التقدم على الوظيفة"])},
    "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نبذه عن الشركة "])},
    "reqAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى تسجيل الدخول للاطلاع على الوظائف المشابهة "])},
    "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرا لاستكمال بياناتك الشخصية"])},
    "beQualified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اصبحت مؤهل لاستقبال الوظيفة التي تناسبك"])},
    "uploadVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع فيديو تعريفي "])},
    "videoTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقصى مدة للفيديو15 دقيقة "])},
    "reUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة رفع"])},
    "chance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لزيادة فرص الحصول على وظيفة الرجاء رفع فيديو لك يختصر ابرز النقاط في سيرتك الذاتية"])},
    "save2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "ryadh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرياض - تبعد 2 كم "])},
    "hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أهلا"])}
  },
  "setting": {
    
  }
}